import './set-public-path';
import './assets/global.scss';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import Vue from 'vue';
import money from 'v-money';

Vue.use(money, { precision: 0, prefix: '$' });

// Version
import version from './version';

// Vendors
const VueSlickCarousel = () => import('vue-slick-carousel');

// Vue Components
const CustomButton = () => import('./component-library/custom-button.vue');
const AppButton = () => import('./component-library/AppButton.vue');
const AppInput = () => import('./component-library/AppInput.vue');
const AppBag = () => import('./component-library/AppBag.vue');
const AppBags = () => import('./component-library/AppBags.vue');
const AppNotify = () => import('./component-library/AppNotify.vue');
const AppBox = () => import('./component-library/AppBox.vue');
const AppModal = () => import('./component-library/AppModal.vue');
const AppSelectProvider = () =>
	import('./component-library/AppSelectProvider.vue');
const AppValue = () => import('./component-library/AppValue.vue');
const AppSearch = () => import('./component-library/AppSearch.vue');
const AppLastTransaction = () =>
	import('./component-library/lastTransaction/AppLastTransaction.vue');
const AppBillTransactionData = () =>
	import('./component-library/AppBillTransactionData.vue');
const AppPublicity = () =>
	import('./component-library/general/AppPublicity.vue');
const AppInConstruction = () =>
	import('./component-library/general/appInConstruction');
const AppNavTop = () => import('./component-library/AppNavTop.vue');
const AppViewLayout = () => import('./component-library/general/viewLayout');
const AppLoader = () => import('./component-library/AppLoader.vue');
const AppSelect = () => import('./component-library/AppSelect.vue');
const AppNotFound = () => import('./component-library/AppNotFound.vue');
const AppDragableList = () =>
	import('./component-library/ui/AppDragableList.vue');
const AppRequirements = () =>
	import('./component-library/user/requirements/AppRequirements.vue');
const AppConfigurations = () =>
	import('./component-library/user/configuration/AppConfigurations.vue');
const AppActivateProcess = () =>
	import('./component-library/user/requirements/AppActivateProcess.vue');
const AppProgressIndicator = () =>
	import('./component-library/ui/ProgressIndicator.vue');
const AppMapSearchBox = () =>
	import('./component-library/form/AppMapSearchBox.vue');
const AppDecideBags = () =>
	import('./component-library/decideBags/AppDecideBags.vue');
const AppRangeSlider = () => import('./component-library/AppRangeSlider.vue');
const AppOtpInput = () => import('./component-library/form/AppOtpInput.vue');
const AppRadioSelect = () =>
	import('./component-library/form/AppRadioSelect.vue');
const AppSlides = () => import('./component-library/AppSlides.vue');
const AppInfo = () => import('./component-library/AppInfo.vue');
const AppSkeleton = () => import('./component-library/AppSkeleton.vue');
const AppWhatsappFab = () => import('./component-library/AppWhatsappFab.vue');
const AppDatePicker = () => import('./component-library/AppDatePicker.vue');
const AppTable = () => import('./component-library/table/AppTable.vue');
const AppSwitch = () => import('./component-library/AppSwitch.vue');
const AppInputAmount = () => import('./component-library/AppInputAmount.vue');
const AppNumPad = () => import('./component-library/AppNumPad.vue');
const AppIcon = () => import('./component-library/AppIcon.vue');
const AppCountDown = () => import('./component-library/AppCountDown.vue');
const InfoCarousel = () => import('./component-library/InfoCarousel.vue');
const AppCheckbox = () => import('./component-library/ui/AppCheckbox.vue');
const AppSpinner = () => import('./component-library/AppSpinner.vue');
const AppBillReference = () => import('./component-library/form/AppBillReference.vue');
const AppContentReceipt = () => import('./component-library/lastTransaction/AppContentReceipt.vue');

// svg-icon-components
const ExcelSvg = () => import('./components-svg/ExcelSvg.vue');
const LeftArrowSvg = () => import('./components-svg/LeftArrowSvg.vue');
const RightArrowSvg = () => import('./components-svg/RightArrowSvg.vue');
const AppAccessSvg = () =>
	import('./components-svg/directAccess/AppAccessSvg.vue');

export { AssetsAdmin, brand } from './assets/imgs';
export {
	version,
	CustomButton,
	AppButton,
	AppInput,
	AppBag,
	AppBags,
	AppNotify,
	AppBox,
	AppModal,
	AppSelectProvider,
	AppValue,
	AppSearch,
	AppLastTransaction,
	AppBillTransactionData,
	AppPublicity,
	AppInConstruction,
	AppNavTop,
	AppViewLayout,
	AppLoader,
	AppSelect,
	AppNotFound,
	AppDragableList,
	AppRequirements,
	AppConfigurations,
	AppActivateProcess,
	AppProgressIndicator,
	AppMapSearchBox,
	AppDecideBags,
	AppRangeSlider,
	AppOtpInput,
	AppSlides,
	AppInfo,
	AppSkeleton,
	AppWhatsappFab,
	AppDatePicker,
	AppTable,
	AppSwitch,
	AppInputAmount,
	AppNumPad,
	AppIcon,
	VueSlickCarousel,
	AppCountDown,
	InfoCarousel,
	AppRadioSelect,
	AppCheckbox,
	AppSpinner,
	AppBillReference,
	AppContentReceipt,
	// svg-icon-components
	ExcelSvg,
	LeftArrowSvg,
	RightArrowSvg,
	AppAccessSvg
};
